import React, {useCallback, useEffect, useState} from 'react';
import type {WP_REST_API_Post as Post} from "wp-types";
import { Link } from 'react-router-dom';

import styles from './WpPostsRow.module.scss';

import Image from '#components/Image/Image';
import wpService from "#lib/wordpress/wp-service";
import TileDock from "#components/TileDock/TileDock";

type FeaturedMedia = {
  source_url?: string;
};

type WpPostsRowProps = {
  title: string | undefined
}

const WpPostsRow = ({title}: WpPostsRowProps): JSX.Element => {
  const [posts, setPosts] = useState<Post[]>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // @ts-ignore
  useEffect(() => {
    wpService.getPosts().then(({ posts }) => setPosts(posts));
  }, []);

  const renderTile = useCallback(
    (post: Post) => {
      const imageUrl = (post?._embedded?.['wp:featuredmedia'] as FeaturedMedia[])?.find((media) => Boolean(media?.source_url))?.source_url ?? 'https://ik.imagekit.io/ikmedia/backlit.jpg';
      return (
        <li key={post.id}>
          <Link
            to={`/posts/${post.slug}`}>
            <Image image={imageUrl} width={320} alt={post.title.rendered} />
            <div className={styles.titleContainer}>
              <h3 className={styles.title}>{post.title.rendered}</h3>
            </div>
          </Link>
        </li>
      );
    },
    [],
  );

  return (
    <>
      <div className={styles.row}>
        {posts && <>
          <h2 className={styles.componentTitle}>{title || "Posts"}</h2>
          <TileDock items={posts} renderTile={renderTile} />
        </>}
      </div>
    </>
  );
};

export default WpPostsRow;
