import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import type { WP_REST_API_Post as Post } from 'wp-types';

import wpService from '#lib/wordpress/wp-service';
import InfiniteScrollLoader from '#components/InfiniteScrollLoader/InfiniteScrollLoader';

function PostPage() {
  const { slug = '' } = useParams<string>();
  const [posts, setPosts] = useState<Post[]>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // @ts-ignore
  useEffect(() => {
    wpService
      .getPosts({
        slug: [slug],
      })
      .then(({ posts }) => setPosts(posts));
  }, [slug]);

  const post = posts ? posts[0] : null;

  //console.log('post', post)

  if (!post) {
    return <InfiniteScrollLoader key="loader" />;
  }

  return (
    <div style={{ padding: '37px 56px 0' }}>
      <div>
        <div>
          <h1>{post.title.rendered}</h1>
          <h2>{post.excerpt.raw}</h2>
        </div>

        <div>
          <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </div>
      </div>
    </div>
  );
}

export default PostPage;

export async function generateStaticParams() {
  const { posts } = await wpService.getPosts({
    per_page: 100,
  });

  return posts.map((post) => ({
    slug: post.slug,
  }));
}
