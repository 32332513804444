import type { WP_REST_API_Post as Post } from 'wp-types';

const API_BASE_URL: string = import.meta.env.WORDPRESS_API_BASE_URL ?? 'https://jw.solutionpress.hr/wp-json';

const API_VERSION = 'v2';
const BASE_URL = `${API_BASE_URL}/wp/${API_VERSION}/`;

const DEFAULT_POSTS_PARAMS = {
  per_page: 5,
  page: 1,
  search: '',
  slug: <string[]>[],
  _embed: true,
};

type GetPostsParams = Partial<typeof DEFAULT_POSTS_PARAMS>;

export default class WpClient {
  async getPosts(params?: GetPostsParams): Promise<{ posts: Post[]; totalPages: number }> {
    const queryParams = this.queryString({
      ...DEFAULT_POSTS_PARAMS,
      ...params,
    });

    const url = `${BASE_URL}posts${queryParams}`;
    const response = await fetch(url);
    const totalPagesHeader = response.headers.get('X-WP-TotalPages');
    const totalPages = totalPagesHeader ? Number(totalPagesHeader) : 0;
    const posts = await response.json();

    return {
      posts,
      totalPages,
    };
  }

  async getPages(params?: GetPostsParams): Promise<{ posts: Post[]; totalPages: number }> {
    const queryParams = this.queryString({
      ...DEFAULT_POSTS_PARAMS,
      ...params,
    });

    const url = `${BASE_URL}pages${queryParams}`;
    const response = await fetch(url);
    const totalPagesHeader = response.headers.get('X-WP-TotalPages');
    const totalPages = totalPagesHeader ? Number(totalPagesHeader) : 0;
    const posts = await response.json();

    return {
      posts,
      totalPages,
    };
  }

  async getPost(id: number): Promise<Post> {
    const response = await fetch(`${BASE_URL}posts/${id}`);

    return response.json();
  }

  async getPage(id: number): Promise<Post> {
    const response = await fetch(`${BASE_URL}pages/${id}`);

    return response.json();
  }

  private queryString(params: { per_page: number; search: string; _embed: boolean; page: number; slug: string[] }) {
    const queryParams = Object.keys(params)
      .map((key) => {
        // @ts-ignore
        return `${key}=${encodeURIComponent(params[key].toString())}`;
      })
      .join('&');

    return queryParams ? `?${queryParams}` : '';
  }
}
